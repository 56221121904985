import ControlDataLoader, {
  Noop,
} from '@components/Shared/entityControl/ControlDataLoader'
import { FormError } from '@components/Ui/FormError'
import { Label, Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { FormikErrors } from 'formik'
import identity from 'ramda/src/identity'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import MultiselectButton from './Button/MultiselectButton'
import MultiselectList from './List/MultiselectList'

type options = { action: 'remove' | 'insert'; dataItem: any }

export interface IMultiselectProps {
  id?: string
  title?: string
  name?: string
  textField?: string
  valueField?: string
  className?: string
  data?: any[]
  dataParser?: (data: any[]) => any[]
  loading?: boolean
  required?: boolean
  cellEditor?: boolean
  hideButton?: boolean
  inline?: boolean
  defaultOpen?: boolean
  value?: any
  placeholder?: string
  disabled?: boolean
  queryVariables?: Record<any, any>
  DataLoader?: any
  labelFontSize?: string
  displayedItemsCount?: number
  allowCreate?: 'onFilter' | false
  onCreate?: (value: any) => Promise<any>
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  onChange: (value: any, options: options) => void
  onBlur?: any
  renderContent?: ({
    value,
    error,
    disabled,
    textField,
    valueField,
    open,
    title,
    placeholder,
    displayedItemsCount,
    onChange,
    renderTooltipItem,
    isItemRemovable,
  }) => React.ReactElement
  renderItem?: ({ item }) => React.ReactElement
  renderList?: ({
    data,
    value,
    valueField,
    textField,
    renderItem,
  }) => React.ReactElement
  isItemRemovable?: ({
    value,
    item,
    data,
  }: {
    value: any
    item: any
    data: any[]
  }) => boolean
  renderTooltipItem?: ({ item }) => React.ReactElement
}

const findChanged = ({
  firstList,
  secondList,
  valueField,
}: {
  firstList: any[]
  secondList: any[]
  valueField: string
}) =>
  firstList.find(
    (item) =>
      !secondList.find((sItem) => sItem[valueField] === item[valueField]),
  )

const Multiselect: React.FunctionComponent<IMultiselectProps> = (props) => {
  const {
    title = '',
    textField = 'name',
    valueField = 'id',
    labelFontSize = 'text-sm',
    data = [],
    defaultOpen,
    hideButton,
    dataParser = identity,
    loading,
    disabled,
    required,
    allowCreate,
    onCreate,
    queryVariables,
    DataLoader = Noop,
    error = null,
    onChange,
    renderItem,
    renderList,
    isItemRemovable = () => true,
  } = props

  const propsData = data
  const propsLoading = loading

  const [buttonElement, setButtonElement] = useState<HTMLDivElement>()
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement>()
  const {
    styles: popperStyles,
    attributes: popperAttributes,
    state: popperState,
    update: updatePopper,
  } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 1],
        },
      },
    ],
  })

  const value = useMemo(() => props.value ?? [], [props.value])

  useEffect(() => {
    // we use props.value, because if there is no value provided
    // it will default to a new empty array on every render
    // and this will result in infinite loop
    if (Array.isArray(props.value)) {
      updatePopper?.()
    }
  }, [props.value, updatePopper])

  useEffect(() => {
    if (defaultOpen && buttonElement) {
      buttonElement.click()
    }
  }, [defaultOpen, buttonElement])

  const onListboxChange = useCallback(
    async (items: any[]) => {
      const createdItem = items.find((item) => item.create)

      if (createdItem) {
        // when create 'option' is selected
        const { create, ...rest } = createdItem

        const created = await onCreate(rest)

        const updatedItems = items.map((item) =>
          item === createdItem ? { ...rest, id: created.id } : item,
        )

        onChange(updatedItems, {
          action: 'insert',
          dataItem: created,
        })
      } else {
        const action = items.length < value.length ? 'remove' : 'insert'
        const args =
          action === 'remove'
            ? { firstList: value, secondList: items, valueField }
            : { firstList: items, secondList: value, valueField }
        const dataItem = findChanged(args)

        const options: options = {
          action,
          dataItem,
        }

        onChange(items, options)
      }
    },
    [value, valueField, onChange, onCreate],
  )

  return (
    <React.Fragment>
      <Listbox
        multiple
        value={value}
        by={valueField}
        disabled={disabled}
        onChange={onListboxChange}
      >
        {({ open }) => (
          <ControlDataLoader
            DataLoader={DataLoader}
            open={open}
            variables={queryVariables}
            fetchPolicy="cache-and-network"
          >
            {(
              { data, loading } = {
                data: propsData,
                loading: propsLoading,
              },
            ) => {
              const parsed = dataParser(data || [])

              const listProps = {
                data: parsed,
                loading,
                value,
                valueField,
                textField,
                renderItem,
                allowCreate,
                isItemRemovable,
                setPopperElement,
                popperStyles,
                popperAttributes,
                popperPlacement: popperState?.placement ?? null,
              }

              return (
                <div className="relative">
                  {title && (
                    <Label
                      as="label"
                      className={classNames([
                        'block relative font-bold text-oxford-gray-800 pb-2',
                        labelFontSize,
                        { required },
                      ])}
                    >
                      {title}
                    </Label>
                  )}

                  <div ref={setReferenceElement}>
                    <MultiselectButton
                      {...props}
                      value={value}
                      isItemRemovable={isItemRemovable}
                      open={open}
                      loading={loading}
                      hideButton={hideButton}
                      popperPlacement={popperState?.placement ?? null}
                      setButtonElement={setButtonElement}
                    />
                  </div>

                  {typeof renderList === 'function' ? (
                    renderList(listProps)
                  ) : (
                    <MultiselectList {...listProps} />
                  )}
                </div>
              )
            }}
          </ControlDataLoader>
        )}
      </Listbox>
      {error && <FormError error={error} />}
    </React.Fragment>
  )
}

export default Multiselect
