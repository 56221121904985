import Appsignal from '@appsignal/javascript'
import { isDevelopment } from '@lib/utilities/environment'

const appsignal = new Appsignal({ key: process.env.NEXT_PUBLIC_APPSIGNAL_KEY })

export const logErrorToRemote = ({
  error,
  tags,
}: {
  error: Error
  tags?: Record<string, string>
}) => {
  if (isDevelopment()) {
    return
  }

  return appsignal.sendError(error, (span) => {
    if (tags) {
      span.setTags(tags)
    }
  })
}

export default appsignal
