import ErrorBoundary from '@components/Error/ErrorBoundary/ErrorBoundary'
import useTeams from '@lib/hooks/useTeams'

export const WithTeamData = ({ children }) => {
  const teams = useTeams()
  return (
    <ErrorBoundary>
      {typeof children === 'function'
        ? children({
            operationName: 'allTeams',
            data: teams,
            previousData: null,
            loading: false,
            error: null,
          })
        : children}
    </ErrorBoundary>
  )
}
