import { ApolloLink, createHttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { triggerReconnection } from '@lib/subscriptions/webSocketReconnectedVar'
import { tokenExpired } from '@utils/auth'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { createClient } from 'graphql-ws'
import { getSession } from 'next-auth/react'
import { ApolloConfig } from './apollo'

const isMutationOperation = (operation: any) =>
  operation.query?.definitions?.reduce(
    (result: boolean, definition: OperationDefinitionNode) =>
      result || definition.operation === 'mutation',
    false,
  )

const getUpToDateSession = async (
  session: ApolloConfig['session'],
  updateSession: ApolloConfig['updateSession'],
) => {
  function sessionResponse(session: ApolloConfig['session'], renewed = false) {
    return {
      session,
      renewed,
    }
  }

  if (
    !session?.bearerToken ||
    (typeof window !== 'undefined' && navigator && !navigator?.onLine)
  )
    return sessionResponse(session)

  if (
    typeof session?.accessTokenExpires === 'number' &&
    tokenExpired(session.accessTokenExpires)
  ) {
    // get an up-to-date session with renewed access token
    const session = await getSession({
      broadcast: false,
    })

    if (session) {
      // update SessionProvider state
      updateSession(session)
    }

    return sessionResponse(session as ApolloConfig['session'], !!session)
  }

  return sessionResponse(session)
}

const isSubscriptionOperation = (
  definition: OperationDefinitionNode | FragmentDefinitionNode,
) =>
  definition.kind === 'OperationDefinition' &&
  definition.operation === 'subscription'

export const httpLink = createHttpLink({
  credentials: 'same-origin',
  uri: process.env.NEXT_PUBLIC_API_URL,
})

const sessionId = crypto.randomUUID()

export const createSplitLink = ({ session, updateSession }: ApolloConfig) => {
  let activeSocket
  let timedOut

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_WS_URL,
      connectionParams: async () => {
        const { session: upToDateSession } = await getUpToDateSession(
          session,
          updateSession,
        )

        return {
          Authorization: upToDateSession?.bearerToken,
          'X-Client-Version': 'Web 1',
          'X-Session-ID': sessionId,
        }
      },
      keepAlive: 50_000,
      shouldRetry: () => true,
      retryAttempts: Infinity,
      on: {
        connected: (socket) => {
          activeSocket = socket

          triggerReconnection()
        },
        ping: (received) => {
          if (!received)
            timedOut = setTimeout(() => {
              if (activeSocket.readyState === WebSocket.OPEN)
                activeSocket.close(4408, 'Request Timeout')
            }, 5_000)
        },
        pong: (received) => {
          if (received) clearTimeout(timedOut)
        },
      },
    }),
  )

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return isSubscriptionOperation(definition)
    },
    wsLink,
    httpLink,
  )
}
export const cleanTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && isMutationOperation(operation)) {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    )
  }
  return forward(operation)
})

export const createAuthLink = ({
  session,
  updateSession,
}: ApolloConfig = {}) => {
  return setContext(async (_, { headers }) => {
    const { session: upToDateSession } = await getUpToDateSession(
      session,
      updateSession,
    )

    const additionalHeaders = upToDateSession
      ? {
          Authorization: upToDateSession?.bearerToken,
        }
      : {}

    return {
      headers: {
        ...headers,
        ...additionalHeaders,
        'X-Client-Version': `Web 1`,
        'X-Session-ID': sessionId,
      },
    }
  })
}
