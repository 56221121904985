import useTranslations from '@locales/useTranslations'
import { CONTRIBUTOR_RIGHTS, CONTRIBUTOR_TYPE } from '@utils/constants'
import UserContributor from '../AccessComponents/UserContributor'
import { User } from '@lib/types/user'
import ContributorContainer from '../AccessComponents/ContributorContainer'
import UserDropdown from '@components/Shared/Dropdown/UserDropdown'
import useContributorActions from '@lib/hooks/useContributorActions'
import { useFormikContext } from 'formik'
import { UserAccess } from '@lib/graphql/__generated__/graphql'

export interface IProjectPublicTab {
  resourceOwner: User
  onOwnerChange: Function
}

const ProjectPublicTab = ({
  resourceOwner,
  onOwnerChange,
}: IProjectPublicTab) => {
  const { t } = useTranslations()

  const { values } = useFormikContext<any>()

  const { onContributorAdd, ...rest } = useContributorActions(
    CONTRIBUTOR_TYPE.USER,
    resourceOwner,
  )

  return (
    <>
      {resourceOwner && (
        <ContributorContainer
          title={t('access.projectOwner')}
          containerClassName="bg-oxford-gray-50"
          contributors={
            <UserContributor
              account={resourceOwner}
              access={CONTRIBUTOR_RIGHTS(t).OWNER.value}
              showBadge={false}
              onOwnerChange={onOwnerChange}
            />
          }
        />
      )}

      <div className="py-3">
        <div className="text-oxford-gray-400 uppercase mb-2 text-xs">
          {t('access.editors')}
        </div>
        {!!values?.userContributors?.length && (
          <div className="pb-3 flex flex-col gap-3">
            {values.userContributors.map(({ account, access, ...restUser }) => (
              <UserContributor
                key={account.id}
                account={account}
                access={access}
                disableAccessDropdown
                {...restUser}
                {...rest}
              />
            ))}
          </div>
        )}
        <UserDropdown
          placeholder={t('buttons.addUserWithEditPermission')}
          onChange={(user) =>
            onContributorAdd(user as User, UserAccess.Editor as any)
          }
          dataParser={(data) =>
            data.filter(
              (user) =>
                user.id !== resourceOwner?.id &&
                !values?.userContributors?.find(
                  ({ account }) => user.id === account.id,
                ),
            )
          }
        />
      </div>
    </>
  )
}

export default ProjectPublicTab
