type LocaleType = Record<string, string>

const DEFAULT_LOCALE: LocaleType = {
  min: 'Field must be at least {min} characters long',
  max: 'Field must be at most {max} characters long',
  minNumber: 'Number must be equal or bigger than {min}',
  minMaxNumber: 'Number must be between {min} and {max}',
  required: 'This field is required',
  invalidPassword:
    'Password must contain at least one capital letter and one special character',
  invalidEmail: 'The email you provided is invalid',
  invalidPhoneNumber: 'Invalid phone number',
  nameAlreadyExists: 'Name already exists',
  emailAlreadyExists: 'Email already exists',
  maxNumOfAssignees: 'The maximum number of assignees cannot exceed',
}

let Locale = DEFAULT_LOCALE

export const setLocale = (locale: LocaleType) => {
  Locale = locale
}

export const getLocale = () => {
  return Locale
}
