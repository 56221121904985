import { TFunction } from '@locales/useTranslations'
import { NOTIFICATION_ACTIONS } from './utils'

export const deadlineRequestNotifications = (t: TFunction) => ({
  [NOTIFICATION_ACTIONS.PENDING_DEADLINE_CHANGE_REQUEST]: (data: any) => (
    <>
      {t('commentLogs.deadlineChangeRequestedOnTask')}{' '}
      <span className="font-semibold">{`"${data?.comment?.task?.name}"`}</span>
    </>
  ),

  [NOTIFICATION_ACTIONS.ACCEPTED_DEADLINE_CHANGE_REQUEST]: (data: any) => (
    <>
      {t('commentLogs.yourDeadlineChangeRequestOnTask')}{' '}
      <span className="font-semibold">{`"${data?.comment?.task?.name}"`}</span>{' '}
      {t('commentLogs.hasBeenAccepted')}
    </>
  ),

  [NOTIFICATION_ACTIONS.REJECTED_DEADLINE_CHANGE_REQUEST]: (data: any) => (
    <>
      {t('commentLogs.yourDeadlineChangeRequestOnTask')}{' '}
      <span className="font-semibold">{`"${data?.comment?.task?.name}"`}</span>{' '}
      {t('commentLogs.hasBeenRejected')}
    </>
  ),

  [NOTIFICATION_ACTIONS.CUSTOM_DEADLINE_CHANGE_REQUEST]: (data: any) => (
    <>
      {t('commentLogs.theOwnerOfTask')}{' '}
      <span className="font-semibold">{`"${data?.comment?.task?.name}"`}</span>{' '}
      {t('commentLogs.hasSetNewDeadline')}
    </>
  ),
})
