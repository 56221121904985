import { TFunction } from '@locales/useTranslations'
import { formatDate, isValidDate, TimeStamp } from '@utils/Date'
import { NOTIFICATION_ACTIONS, parseNotificationText } from './utils'

const getActivityDeadlineTextKey = (action: string) => {
  if (action === NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_DEADLINE_CHANGED) {
    return 'commentLogs.deadLineOfMilestonePP'
  }
  if (action === NOTIFICATION_ACTIONS.KEY_ACTIVITIES_DEADLINE_CHANGED) {
    return 'commentLogs.deadLineOfMilestoneKA'
  }

  return 'commentLogs.deadLineOfMilestone'
}

const getActivityReminderTextKey = (action: string) => {
  if (action === NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_REMINDER_CHANGED) {
    return 'commentLogs.notifyMeDateOfMilestonePP'
  }
  if (action === NOTIFICATION_ACTIONS.KEY_ACTIVITIES_REMINDER_CHANGED) {
    return 'commentLogs.notifyMeDateOfMilestoneKA'
  }

  return 'commentLogs.notifyMeDateOfMilestone'
}

const getActivityExecDateTextKey = (action: string) => {
  if (
    action === NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED
  ) {
    return 'commentLogs.milestoneExecDatePP'
  }
  if (action === NOTIFICATION_ACTIONS.KEY_ACTIVITIES_EXECUTION_DATE_CHANGED) {
    return 'commentLogs.milestoneExecDateKA'
  }

  return 'commentLogs.milestoneExecDate'
}

const createActivityNotification = (t: TFunction, getTextKey: Function) => {
  const NotificationComponent = (data: any) => (
    <>
      {t(getTextKey(data.action))}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.milestone?.name,
      )}"`}</span>{' '}
      {isValidDate(data?.snapshot as TimeStamp)
        ? `${t('commentLogs.hasBeenChangedTo')} ${formatDate(
            data?.snapshot as TimeStamp,
          )}`
        : `${t('commentLogs.hasBeenRemoved')}`}
    </>
  )
  return NotificationComponent
}

export const activityNotifications = (t: TFunction) => ({
  [NOTIFICATION_ACTIONS.MILESTONE_DEADLINE_CHANGED]: createActivityNotification(
    t,
    getActivityDeadlineTextKey,
  ),
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_DEADLINE_CHANGED]:
    createActivityNotification(t, getActivityDeadlineTextKey),
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_DEADLINE_CHANGED]:
    createActivityNotification(t, getActivityDeadlineTextKey),
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_REMINDER_CHANGED]:
    createActivityNotification(t, getActivityReminderTextKey),
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_REMINDER_CHANGED]:
    createActivityNotification(t, getActivityReminderTextKey),
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED]:
    createActivityNotification(t, getActivityExecDateTextKey),
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_EXECUTION_DATE_CHANGED]:
    createActivityNotification(t, getActivityExecDateTextKey),
})
