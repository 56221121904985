import { ellipsize } from '@utils/common'
import path from 'ramda/src/path'

export const NOTIFICATION_TYPES = {
  GOAL: 'Goal',
  TASK: 'Task',
  PROJECT: 'Project',
  MILESTONE: 'Milestone',
  COMMENT: 'Comment',
  COMMENT_REPLY: 'CommentReply',
  COMMENT_REMINDER: 'CommentReminder',
  DEADLINE_REQUEST: 'DeadlineRequest',
  EXPORT: 'Export',
}

export const NOTIFICATION_ACTIONS = {
  CANCELED_TASK: 'canceledTask',
  CANCELED_GOAL: 'canceledGoal',
  DEADLINE_CHANGED: 'deadlineChanged',
  PROJECT_DEADLINE_CHANGED: 'projectDeadlineChanged',
  MILESTONE_DEADLINE_CHANGED: 'milestoneDeadlineChanged',
  GOAL_DEADLINE_CHANGED: 'goalDeadlineChanged',
  ASSIGNEE_CHANGED: 'assigneeChanged',
  OWNER_CHANGED: 'ownerChanged',
  NEW_COMMENT: 'newComment',
  THREAD_NEW_COMMENT: 'threadNewComment',
  NEW_TASK: 'newTask',
  NEW_GOAL: 'newGoal',
  INPROGRESS_TASK: 'inprogressTask',
  INPROGRESS_GOAL: 'inprogressGoal',
  DONE_TASK: 'doneTask',
  DONE_GOAL: 'doneGoal',
  DELETED_TASK: 'deletedTask',
  DELETED_GOAL: 'deletedGoal',
  COMMENT_REMINDER: 'commentReminder',
  COMMENT_LIKE: 'commentLike',
  THREAD_COMMENT_REMINDER: 'threadCommentReminder',
  PENDING_DEADLINE_CHANGE_REQUEST: 'pendingDeadlineChangeRequest',
  ACCEPTED_DEADLINE_CHANGE_REQUEST: 'acceptedDeadlineChangeRequest',
  REJECTED_DEADLINE_CHANGE_REQUEST: 'rejectedDeadlineChangeRequest',
  CUSTOM_DEADLINE_CHANGE_REQUEST: 'customDeadlineChangeRequest',
  PRODUCTION_PLANNING_REMINDER_CHANGED: 'milestoneReminderChangedPP',
  PRODUCTION_PLANNING_DEADLINE_CHANGED: 'milestoneDeadlineChangedPP',
  PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED: 'milestoneExecutionDateChangedPP',
  KEY_ACTIVITIES_REMINDER_CHANGED: 'milestoneReminderChangedKA',
  KEY_ACTIVITIES_EXECUTION_DATE_CHANGED: 'milestoneExecutionDateChangedKA',
  KEY_ACTIVITIES_DEADLINE_CHANGED: 'milestoneDeadlineChangedKA',
  EXPORT_READY: 'exportReady',
}

const NOTIFICATIONS_ICONS = {
  [NOTIFICATION_ACTIONS.NEW_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-sticky-note text-oxford-gray-400',
  },
  [NOTIFICATION_ACTIONS.DONE_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-jade-50 rounded-full',
    iconClassName: 'far fa-check text-jade-500',
  },
  [NOTIFICATION_ACTIONS.DELETED_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-trash-alt text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.CANCELED_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-ban text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.NEW_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-sticky-note text-oxford-gray-400',
  },
  [NOTIFICATION_ACTIONS.DONE_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-jade-50 rounded-full',
    iconClassName: 'far fa-check text-jade-500',
  },
  [NOTIFICATION_ACTIONS.DELETED_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-trash-alt text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.CANCELED_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-ban text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.NEW_COMMENT]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-comment-alt-dots text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.THREAD_NEW_COMMENT]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-comment-alt-dots text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.COMMENT_REMINDER]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-alarm-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.THREAD_COMMENT_REMINDER]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-alarm-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.COMMENT_LIKE]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-thumbs-up text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.OWNER_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-repeat text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PROJECT_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.MILESTONE_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.GOAL_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PENDING_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-comment-question text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.ACCEPTED_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-thumbs-up text-jade-500',
  },
  [NOTIFICATION_ACTIONS.REJECTED_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-thumbs-down text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.CUSTOM_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-arrows-repeat text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.ASSIGNEE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-exchange text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.INPROGRESS_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-exchange text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.INPROGRESS_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-exchange text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-table text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_REMINDER_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-bell text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_EXECUTION_DATE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-table text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_REMINDER_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-bell text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.EXPORT_READY]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-file-export text-cornflower-blue-400',
  },
}

export const getNotificationIcon = (action: string) =>
  NOTIFICATIONS_ICONS[action]

export const parseNotificationText = (
  value: string | undefined,
  maxTextLength = 50,
) => (value ? ellipsize(maxTextLength)(value) : '<missing>')

export const formatNotificationSubtitute = (data, t) => {
  const newAssignee = path(['snapshot', 'account', 0, 'fullName'])(data)

  if (newAssignee) {
    return `${t('commentLogs.hasBeenSubstitutedWith')} ${newAssignee}`
  } else {
    return t('commentLogs.hasBeenRemoved')
  }
}
