export const REMOTE_DATA_GRID_REFRESH_CACHE = 'remote_data_grid/refresh_cache'

export const NOTIFICATION_TRANSACTION =
  'remote_data_grid/notification_transaction'

export const RESOURCE_UPLOADER_PROGRESS = 'resource_uploader/progress'

export const RESOURCE_UPLOADER_ABORT_UPLOAD = 'resource_uploader/abortUpload'

export const RESOURCE_UPLOADER_FILE_ERROR = 'resource_uploader/fileError'

export const GRID_RESET = 'grid/reset'

export const NEW_THREAD_RESET_FORM = 'new_thread/reset_form'
