export const FILTER_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  DATE_TIME: 'dateTime',
  SET: 'set',
  BOOLEAN: 'boolean',
} as const

export const FILTER_OPERATORS = {
  contains: 'CONTAINS',
  equals: 'EQUALS',
  inRange: 'BETWEEN',
  greaterThanOrEqual: 'GREATER_THAN_OR_EQUAL',
  lessThanOrEqual: 'LESS_THAN_OR_EQUAL',
  in: 'IN',
}

export const FILTER_COLUMNS = {
  name: 'NAME',
  assignedProducers: 'PRODUCERS',
  hasPriority: 'HAS_PRIORITY',
  projectType: 'PROJECT_TYPE',
  timeFactor: 'TIME_FACTOR',
  reference: 'REFERENCE',
  responsible: 'RESPONSIBLE',
  producers: 'PRODUCERS',
  project: 'PROJECT',
  collections: 'COLLECTIONS',
  clients: 'CLIENTS',
  client: 'CLIENT',
  deadline: 'DEADLINE',
  executionDate: 'EXECUTION_DATE',
  quantity: 'QUANTITY',
  delay: 'DELAY',
  notes: 'NOTES',
  status: 'STATUS',
  dynamicPlanner: 'DYNAMIC_PLANNER',
  assignedTo: 'ASSIGNED_TO',
  assignee: 'ASSIGNEE',
  followers: 'FOLLOWERS',
  milestone: 'MILESTONE',
  order: 'ORDER',
  owner: 'OWNER',
  user: 'USER',
  providers: 'PROVIDERS',
  remindMe: 'REMIND_ME',
  read: 'READ',
  email: 'EMAIL',
  firstName: 'FIRST_NAME',
  lastName: 'LAST_NAME',
  isActive: 'IS_ACTIVE',
  jobTitle: 'JOB_TITLE',
  phone: 'PHONE',
  roles: 'ROLES',
  guests: 'GUESTS',
  members: 'MEMBERS',
  teamLead: 'TEAM_LEAD',
  address: 'ADDRESS',
  createdAt: 'CREATED_AT',
  createdBy: 'CREATED_BY',
  createdOn: 'CREATED_ON',
  sizeStandard: 'SIZE_STANDARD',
  type: 'TYPE',
  action: 'ACTION',
  access: 'ACCESS',
  category: 'CATEGORY',
  date: 'DATE',
  size: 'SIZE',
  source: 'SOURCE',
  baseSource: 'BASE_SOURCE',
  directSource: 'DIRECT_SOURCE',
  sourceType: 'SOURCE_TYPE',
  uploadedBy: 'UPLOADED_BY',
  postedIn: 'POSTED_IN',
  dpd: 'DPD',
  targetDeadline: 'TARGET_DEADLINE',
  dynamicReminder: 'DYNAMIC_REMINDER',
  label: 'LABEL',
  labels: 'LABELS',
  expiresAt: 'EXPIRES_AT',
  duration: 'DURATION',
  boards: 'BOARDS',
  board: 'BOARD',
  goal: 'GOAL',
  manufacturer: 'MANUFACTURER',
  isProcessing: 'IS_PROCESSING',
  leadTime: 'LEAD_TIME',
  isInProduction: 'IS_IN_PRODUCTION',
  content: 'CONTENT',
  colour: 'COLOUR',
  referenceNumber: 'REFERENCE_NUMBER',
  supplier: 'SUPPLIER',
  number: 'NUMBER',
  projects: 'PROJECTS',
  materials: 'MATERIALS',
  manufacturers: 'MANUFACTURERS',
  reminder: 'REMINDER',
  isFullyOrdered: 'IS_FULLY_ORDERED',
  isFullyReserved: 'IS_FULLY_RESERVED',
  materialType: 'MATERIAL_TYPE',
  materialReferenceNumber: 'MATERIAL_REFERENCE_NUMBER',
  isBomComplete: 'IS_BOM_COMPLETE',
}

export const SORT_DIRECTION = {
  asc: 'ASC',
  desc: 'DESC',
}
