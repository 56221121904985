import {
  setFilter,
  textFilter,
} from '@components/Shared/Grid/RemoteDataSource/Filters'
import {
  FILTER_COLUMNS,
  SORT_DIRECTION,
} from '@components/Shared/Grid/RemoteDataSource/Filters/constants'
import {
  Board,
  BoardField,
  FilterOperator,
  Section,
  Status,
} from '@lib/graphql/__generated__/graphql'
import { IBoardsNavigationFilter } from '@lib/graphql/variables/boardsNavigationFilter'
import { Goal } from '@lib/types/goals'
import { Label } from '@lib/types/project'
import { User } from '@lib/types/user'
import { getDateFromTimeStamp, isValidDate } from '@utils/Date'
import isEmpty from 'ramda/src/isEmpty'
import prop from 'ramda/src/prop'
import uniqBy from 'ramda/src/uniqBy'

export const BOARD_DRAFT_SECTION_PLACEHOLDER = '{{ draft }}'

export const SEARCH_BOARDS_PAGE_SIZE = 30

export const getSearchBoardsInput = ({ filterVar, accountId, page = 0 }) => {
  const { showArchived, showOnlyMyBoards, keyword } = filterVar

  const keywordFilter = keyword?.trim().toLowerCase() ?? ''
  const filters = []

  if (keywordFilter.length) {
    filters.push(
      ...textFilter(
        BoardField.Name,
        { filter: keywordFilter },
        FilterOperator.Contains,
      ),
    )
  }

  if (!showArchived) {
    filters.push(
      ...setFilter(BoardField.IsArchived, {
        values: [showArchived],
      }),
    )
  }

  if (showOnlyMyBoards) {
    filters.push(
      ...setFilter(BoardField.Owner, {
        values: [accountId],
      }),
    )
  }

  return {
    pageRequest: {
      page,
      size: SEARCH_BOARDS_PAGE_SIZE,
    },
    filters,
    sorters: [
      {
        field: FILTER_COLUMNS.name,
        direction: SORT_DIRECTION.asc,
      },
    ],
  }
}

export const BOARD_FILTERS = {
  DELAYED_GOALS: 'DELAYED_GOALS',
  KEYWORD: 'KEYWORD',
  LABELS: 'LABELS',
  OWNER: 'OWNER',
  ASSIGNEE: 'ASSIGNEE',
  STATUS: 'STATUS',
} as const

export const boardFilterResolvers = {
  [BOARD_FILTERS.DELAYED_GOALS]: (showDelayed: boolean) => (goal: Goal) => {
    if (!showDelayed) return true

    if (!isValidDate(goal.deadline)) return false

    return getDateFromTimeStamp(goal.deadline, true) < new Date()
  },
  [BOARD_FILTERS.KEYWORD]: (value: string) => (goal: Goal) => {
    if (!value || isEmpty(value)) return true

    return goal.name.toLowerCase().includes(value.trim().toLowerCase())
  },
  [BOARD_FILTERS.LABELS]: (value: Label[]) => (goal: Goal) => {
    if (isEmpty(value)) return true

    return value.every(
      (label) => !!goal?.labels?.find((v) => v.id === label.id),
    )
  },
  [BOARD_FILTERS.OWNER]: (value: User[]) => (goal: Goal) => {
    if (isEmpty(value)) return true

    return value.find((v) => v.id === goal.owner?.id)
  },
  [BOARD_FILTERS.ASSIGNEE]: (value: User[]) => (goal: Goal) => {
    if (isEmpty(value)) return true

    return value.find((v) => v.id === goal.assignee?.id)
  },
  [BOARD_FILTERS.STATUS]: (value: Status[]) => (goal: Goal) =>
    value.find((v) => v === goal.status),
}

export const extractBoardsFromSections = (sections: Section[]): Board[] => {
  if (!sections) return []

  return uniqBy(
    prop('id'),
    sections.map(({ board }) => board),
  )
}

export const validateNavigationFilter = (
  navigationFilters: IBoardsNavigationFilter,
) => {
  const { keyword, ...rest } = navigationFilters

  return {
    ...rest,
    keyword:
      typeof keyword === 'string' && keyword?.trim()?.length > 2
        ? keyword
        : null,
  }
}
