import { TFunction } from '@locales/useTranslations'
import { formatDate } from '@utils/Date'
import { isToDo } from '@utils/Task'
import { NOTIFICATION_ACTIONS, parseNotificationText } from './utils'

export const commentNotifications = (t: TFunction) => ({
  [NOTIFICATION_ACTIONS.COMMENT_LIKE]: (data: any) => (
    <>
      {data.actor?.fullName ?? ''} {t('expressions.likedYourComment')}
      {data.comment?.task ? (
        <>
          {' '}
          {t('expressions.onTask')}{' '}
          <span className="font-semibold">
            {data?.comment?.task?.name ?? ''}
          </span>
        </>
      ) : data.comment?.thread ? (
        <>
          {t('expressions.onThread')}{' '}
          <span className="font-semibold">
            {data?.comment?.thread?.title ?? ''}
          </span>
        </>
      ) : (
        <>
          {t('expressions.onGoal')}{' '}
          <span className="font-semibold">
            {data?.comment?.goal?.name ?? ''}
          </span>
        </>
      )}
    </>
  ),

  [NOTIFICATION_ACTIONS.NEW_COMMENT]: (data: any) => (
    <>
      {data.actor?.fullName ?? ''}{' '}
      {t(
        `expressions.${
          data.comment?.goal
            ? 'commentedOnGoal'
            : isToDo(data.comment?.task)
            ? 'commentedOnToDo'
            : 'commentedOnTask'
        }`,
      )}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.comment?.task?.name ?? data.comment?.goal?.name,
        70,
      )}"`}</span>
    </>
  ),

  [NOTIFICATION_ACTIONS.THREAD_NEW_COMMENT]: (data: any) => (
    <>
      {data.actor?.fullName ?? ''} {t('expressions.commentedOnThread')}
      <span className="font-semibold">{`"${parseNotificationText(
        data.comment?.thread?.title,
        70,
      )}"`}</span>
    </>
  ),
})

export const commentReplyNotifications = (t: TFunction) => ({
  [NOTIFICATION_ACTIONS.THREAD_NEW_COMMENT]: (data: any) => (
    <>
      {data.actor?.fullName ?? ''}{' '}
      {t('expressions.repliedToYourCommentOnThread')}
      <span className="font-semibold">{`"${parseNotificationText(
        data.comment?.thread?.title,
        70,
      )}"`}</span>
    </>
  ),

  [NOTIFICATION_ACTIONS.NEW_COMMENT]: (data: any) => (
    <>
      {data.actor?.fullName ?? ''}{' '}
      {t(
        `expressions.${
          data.comment?.goal
            ? 'repliedToYourCommentOnGoal'
            : isToDo(data.comment?.task)
            ? 'repliedToYourCommentOnToDo'
            : 'repliedToYourCommentOnTask'
        }`,
      )}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.comment?.task?.name ?? data.comment?.goal?.name,
        70,
      )}"`}</span>
    </>
  ),
})

export const commentReminderNotifications = (t: TFunction) => ({
  [NOTIFICATION_ACTIONS.COMMENT_REMINDER]: (data: any) => (
    <>
      {t('expressions.reminderForComment')} {formatDate(data.createdAt, true)}
    </>
  ),

  [NOTIFICATION_ACTIONS.THREAD_COMMENT_REMINDER]: (data: any) => (
    <>
      {t('expressions.reminderForComment')} {formatDate(data.createdAt, true)}
    </>
  ),
})
