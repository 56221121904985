import React from 'react'
import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react'
import { Button } from '@components/Ui'
import { BUTTON_SIZE } from '@components/Ui/Button/Button'
import appsignal from '@lib/appsignal/appsignal'
import useUser from '@lib/hooks/useUser'
import { useRouter } from '@components/Router/useRouter'

interface IErrorBoundaryProps {
  children: any
  tags?: {
    [key: string]: string
  }
}

const FallbackComponent = ({ error }: { error?: any }) => {
  const reloadApp = () => {
    window.location.reload()
  }

  return (
    <div className="p-3 flex flex-col justify-center items-center row-span-2">
      <div className="mb-2">
        <i className="far fa-circle-exclamation text-2xl text-watermelon-800" />
      </div>
      <p className="text-oxford-gray-800 font-semibold mb-3 text-lg">
        There was an error
      </p>

      <Button size={BUTTON_SIZE.SMALL} onClick={reloadApp}>
        Reload page
      </Button>
    </div>
  )
}

const ErrorBoundary = ({ children, tags }: IErrorBoundaryProps) => {
  const { account } = useUser({ fetchPolicy: 'cache-only' })
  const router = useRouter()

  if (process.env.NODE_ENV === 'development') {
    return children
  }

  return (
    <AppSignalErrorBoundary
      instance={appsignal}
      tags={{
        ...tags,
        pathname: router.pathname,
        userId: account?.id,
        organizationId: account?.organization.id,
        organizationName: account?.organization.name,
        timezone: account?.timezone,
        lang: account?.lang,
      }}
      fallback={(error) => <FallbackComponent error={error} />}
    >
      {children}
    </AppSignalErrorBoundary>
  )
}

export default ErrorBoundary
