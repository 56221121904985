import {
  DynamicPlanning,
  Milestone,
  TimestampInput,
  UpdateMilestoneInput,
} from '@lib/graphql/__generated__/graphql'
import { ENTITY_STATUS } from './constants'
import { REMINDER_TYPE_FEATURES } from './Dashboard/dashboardReminderUtil'
import { getDateFromTimeStamp, isValidDate } from './Date'
import { isServiceMilestone } from './milestones'

export const serializeMilestoneUpdate = ({
  label,
  name,
  position,
  id,
  baseId,
  duration,
  dynamicPlanner,
  executionDate,
  isAfterProjectEnd,
  notes,
  targetDeadline,
}: Milestone) => {
  return {
    id,
    labelId: label?.id,
    baseId,
    duration,
    isAfterProjectEnd,
    name,
    notes,
    position,
    dynamicPlanner: dynamicPlanner as TimestampInput,
    executionDate: executionDate as TimestampInput,
    targetDeadline: targetDeadline as TimestampInput,
  } satisfies UpdateMilestoneInput
}

export const isDynamicPlannerEditable = (params) => {
  const { data } = params
  const task = data?.task

  return (
    !isServiceMilestone(params) &&
    data?.status !== ENTITY_STATUS.CANCELED &&
    (isValidDate(task?.deadline)
      ? getDateFromTimeStamp(task.deadline).getTime() > new Date().getTime()
      : true)
  )
}

export const shouldRenderFeatureReminder = ({ reminderType, features }) => {
  const feature = REMINDER_TYPE_FEATURES[reminderType]

  return feature ? features.includes(feature) : true
}

export const getDynamicPlanningRowId = (data: DynamicPlanning) => {
  return (
    data?.milestone?.id ||
    data?.goal?.id ||
    data?.task?.id ||
    data?.projectMaterial?.id ||
    data?.project?.id ||
    data?.order?.id ||
    data?.thread?.id ||
    data?.productionPlanningProject?.id ||
    data?.keyMilestonesProject?.id
  )
}
