import { formatDate, TimeStamp } from '@utils/Date'
import { NOTIFICATION_ACTIONS, parseNotificationText } from './utils'

export const projectNotifications = (t: any) => ({
  [NOTIFICATION_ACTIONS.PROJECT_DEADLINE_CHANGED]: (data: any) => (
    <>
      {t('commentLogs.deadLineOfProject')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.project?.name)}"`}
      </span>{' '}
      {t('commentLogs.hasBeenChangedTo')}{' '}
      {formatDate(data.snapshot as TimeStamp)}
    </>
  ),
})
