import { SAVE_TEAM } from '@lib/graphql/mutations/Organization'
import { ALL_TEAMS } from '@lib/graphql/queries/Organization'
import lensPath from 'ramda/src/lensPath'
import set from 'ramda/src/set'
import view from 'ramda/src/view'
import makeEntityMultiselect from '../entityControl/makeEntityMultiselect'
import { WithTeamData } from '../Team/TeamProvider'

export default makeEntityMultiselect(WithTeamData, {
  allowCreate: false,
  createOptions: {
    operationName: 'saveTeam',
    mutation: SAVE_TEAM,
    updateQuery: ALL_TEAMS,
    updateResolver: (queryData, result) => {
      const lens = lensPath(['searchTeams', 'teams'])
      const collection = view(lens, queryData)
      return set(lens, [result.data?.saveTeam, ...collection])(queryData)
    },
  },
  serializeCreate: (data) => data,
})
