import fetch from 'node-fetch'
import { METHODS } from '@utils/constants'
import { signIn, signOut, SignOutParams } from 'next-auth/react'
import * as SessionStorage from '@utils/SessionStorage'

const cleanUpStorage = () => {
  SessionStorage.clearState()
}

export const signOutClient = (params?: SignOutParams<boolean>) => {
  if (typeof window !== 'undefined') {
    cleanUpStorage()
    return signOut(params)
  }
}

export const signInClient = (...params) => {
  if (typeof window !== 'undefined') {
    signOutClient({
      redirect: false,
    })

    signIn(...params)
  }
}

export const parseJwt = <T extends object = { [k: string]: any }>(
  token: string,
): T | undefined => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch {
    return undefined
  }
}

export const getExpiryDate = (expiresIn: number) =>
  Date.now() + expiresIn * 1000

export const tokenExpired = (accessTokenExpires: number) =>
  // Consider expired a little earlier to avoid a scenario where the token is still valid but by the time the request
  // is made it's expired
  Date.now() - 60 * 1000 >= accessTokenExpires

export const constructBearerToken = (tokenType, accessToken: string) =>
  `${tokenType} ${accessToken}`

export const refreshBearerToken = async ({ refreshToken }) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  const body = {
    client_id: process.env.AUTH0_CLIENT_ID,
    client_secret: process.env.AUTH0_CLIENT_SECRET,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  }

  const response = await fetch(
    `https://${process.env.AUTH0_DOMAIN}/oauth/token`,
    {
      method: METHODS.POST,
      body: JSON.stringify(body),
      headers,
    },
  )

  const json = await response.json()

  if (!response.ok) throw json

  if (json.access_token) {
    const { access_token, refresh_token, token_type, expires_in } = json

    return {
      bearerToken: constructBearerToken(token_type, access_token),
      refreshToken: refresh_token || refreshToken,
      expiresIn: expires_in,
    }
  } else {
    throw json
  }
}
