import Authorizator from '@components/Authorization/Authorizator'
import { createAdminPanelAuthorizator } from '@components/Authorization/authorizators/adminPanelAuthorizator'
import Dropdown from '@components/Ui/Dropdown'
import type { IDropdown } from '@components/Ui/Dropdown/Dropdown'
import { DropdownClearButton } from '@components/Ui/Dropdown/DropdownClearButton'
import useSaveMutation, {
  UseSaveMutationOptions,
} from '@lib/hooks/useSaveMutation'
import type { UUID } from '@lib/types/general'
import invariant from '@utils/invariant'
import { FormikErrors } from 'formik'
import identity from 'ramda/src/identity'
import omit from 'ramda/src/omit'
import path from 'ramda/src/path'
import React from 'react'

export interface IDefaultProps extends Partial<IEntityDropdownProps> {
  managePermission?: string
  allowCreate?: 'onFilter' | false
  createOptions?: UseSaveMutationOptions & { operationName: string }
  serializeCreate?: (item: any) => any
  queryVariables?: Record<any, any>
  renderItem?: IDropdown['renderItem']
}

export interface IEntityDropdownProps extends IDropdown {
  id?: string
  name?: string
  value?:
    | (Record<string, any> & {
        id?: UUID | number
        name?: string
      })
    | null
  title?: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  className?: string
  onChange: any
  allowCreate?: 'onFilter' | false
  onCreate?: (item: any) => void
  autoFocus?: boolean
}

const makeEntityDropdown = (
  DataLoader: React.FunctionComponent<any>,
  defaultProps: IDefaultProps,
) => {
  const {
    allowCreate,
    createOptions,
    serializeCreate = identity,
    renderItem,
    queryVariables,
    managePermission,
    ...restDefaultProps
  } = defaultProps
  if (allowCreate) {
    invariant(!!createOptions, '"createOptions" should be specified')
  }

  function EntityDropdown({
    onChange,
    allowCreate,
    ...rest
  }: IEntityDropdownProps) {
    const [save] = useSaveMutation(createOptions)

    const adminPanelAuthorizator = createAdminPanelAuthorizator()

    const onChangeExtended = (data) =>
      onChange(data ? omit(['isActive'])(data) : data)

    const onCreate = async (data) => {
      const response = await save({
        variables: {
          input: serializeCreate(data),
        },
      })
      const created = path(['data', createOptions.operationName])(response)

      onChangeExtended(created)
    }

    const getAllowCreate = (manageAuthorized: boolean) => {
      if (!manageAuthorized) return false

      if (typeof allowCreate !== 'undefined') {
        return allowCreate
      }

      return defaultProps.allowCreate
    }

    return (
      <Authorizator
        custom={adminPanelAuthorizator.createManageAuthorizator(
          managePermission,
        )}
      >
        {({ authorized: manageAuthorized }) => (
          <Dropdown
            onChange={onChangeExtended}
            allowCreate={getAllowCreate(manageAuthorized)}
            onCreate={onCreate}
            renderClearButton={(onClear) => (
              <DropdownClearButton onClick={onClear} />
            )}
            renderItem={renderItem}
            queryVariables={queryVariables}
            DataLoader={DataLoader}
            {...restDefaultProps}
            {...rest}
          />
        )}
      </Authorizator>
    )
  }

  return EntityDropdown
}

export default makeEntityDropdown
