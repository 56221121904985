import { ErrorResponse, onError } from '@apollo/client/link/error'
import { getMainDefinition, Observable } from '@apollo/client/utilities'
import { logErrorToRemote } from '@lib/appsignal/appsignal'
import { showFailureToast } from '@lib/graphql/variables/toastState'
import { SessionExtended } from '@lib/types/nextauth'
import { signOutClient } from '@utils/auth'
import { GRAPHQL_EXTENSIONS_CODE, HTTP_STATUS } from '@utils/constants'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { getSession } from 'next-auth/react'

const isSubscriptionOperation = (
  definition: OperationDefinitionNode | FragmentDefinitionNode,
) =>
  definition.kind === 'OperationDefinition' &&
  definition.operation === 'subscription'

const isQueryOperation = (
  definition: OperationDefinitionNode | FragmentDefinitionNode,
) =>
  definition.kind === 'OperationDefinition' && definition.operation === 'query'

const getDisplayedMessageForCode = (code) => {
  if (!code) return null

  //TODO: translate
  const messages = {
    [GRAPHQL_EXTENSIONS_CODE.NOT_FOUND]: 'Entity not found',
    [GRAPHQL_EXTENSIONS_CODE.CONFLICT]: 'Conflict on update',
    [GRAPHQL_EXTENSIONS_CODE.FORBIDDEN]: 'Operation forbidden',
    [GRAPHQL_EXTENSIONS_CODE.INTERNAL_ERROR]: 'Internal error',
  }

  return messages[code] ?? null
}

const displayError = ({
  error,
  consoleError,
}: {
  error: string
  consoleError?: string
}) => {
  console.log(consoleError || error)
  showFailureToast(error)
}

const handleUnauthorized = (errorResponse: ErrorResponse) => {
  const { operation, forward } = errorResponse

  return new Observable<any>((observer) => {
    const forceLogout = async () => {
      signOutClient({
        callbackUrl:
          typeof window !== 'undefined'
            ? window.location.origin + '/auth/signed-out'
            : undefined,
      })
    }

    // Try to refresh the token and logout if this operation fails
    getSession({
      broadcast: false,
    })
      .then((session) => {
        if (!(session as SessionExtended)?.bearerToken) {
          throw 'Token not refreshed'
        } else {
          const headers = operation.getContext()?.headers

          operation.setContext({
            headers: {
              ...headers,
              Authorization: (session as SessionExtended)?.bearerToken,
            },
          })
          const subscriber = {
            next: observer.next.bind(observer),
            error: (networkError) => {
              observer.error.bind(observer)(networkError)

              if (networkError?.statusCode === HTTP_STATUS.UNAUTHORIZED) {
                forceLogout()
              }
            },
            complete: observer.complete.bind(observer),
          }
          forward(operation).subscribe(subscriber)
        }
      })
      .catch((error) => {
        observer.error(error)

        logErrorToRemote({
          error: new Error('Unauthorized'),
        })

        forceLogout()
      })
  })
}

const networkErrorHandler = (errorResponse: ErrorResponse) => {
  const { networkError } = errorResponse

  //@ts-expect-error
  if (networkError?.statusCode === HTTP_STATUS.UNAUTHORIZED) {
    return handleUnauthorized(errorResponse)
  } else {
    const fullError = `[Network error]: ${networkError}`

    displayError({ error: fullError })
  }
}

const graphQLErrorHandler = ({ graphQLErrors, definition }) => {
  graphQLErrors.map(({ message, locations, path, extensions }) => {
    if (extensions.classification === 'NullValueInNonNullableField') {
      // ignore the following error
      // 'The field at path '/saveResources' was declared as a non null type, but the code involved in retrieving data has wrongly returned a null value.'
      return
    }
    if (
      isQueryOperation(definition) &&
      [
        GRAPHQL_EXTENSIONS_CODE.FORBIDDEN,
        GRAPHQL_EXTENSIONS_CODE.NOT_FOUND,
      ].includes(extensions?.code as any)
    ) {
      // ignore these errors on queries
      return
    }

    const displayedMessage =
      getDisplayedMessageForCode(extensions?.code) || message
    const fullError = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`

    showFailureToast(displayedMessage || message)

    displayError({ error: displayedMessage, consoleError: fullError })
  })
}

const subscriptionErrorHandler = () => {
  // ignore subscription related errors
  return
}

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const definition = getMainDefinition(operation.query)

    if (isSubscriptionOperation(definition)) {
      return subscriptionErrorHandler()
    }

    if (networkError) {
      return networkErrorHandler({ networkError, operation, forward })
    }

    if (graphQLErrors) {
      return graphQLErrorHandler({ graphQLErrors, definition })
    }
  },
)
