import {
  translate as translateInternal,
  TranslationContext,
} from '@locales/TranslationsProvider/TranslationsProvider'
import { useCallback, useContext, useEffect } from 'react'

export type TFunction = (key: string, keywords?: string[]) => string

export type UseTranslations = () => {
  t: TFunction
  lang: string
}

const checkContext = (translationContext) => {
  if (!translationContext?.lang) {
    throw new Error(
      "Translations Context not found. You can't use this hook outside 'TranslationsProvider'.",
    )
  }

  if (!translationContext?.translations) {
    throw new Error(
      `Translations missing for this language '${translationContext.lang}'`,
    )
  }
}

const useTranslations: UseTranslations = () => {
  const translationsContext = useContext(TranslationContext)

  useEffect(() => {
    checkContext(translationsContext)
  }, [translationsContext])

  const translate: TFunction = useCallback(
    (key, keywords) => {
      const { translations } = translationsContext
      return translateInternal(key, keywords, {
        translations,
      })
    },
    [translationsContext],
  )

  return { t: translate, lang: translationsContext?.lang }
}

export default useTranslations
