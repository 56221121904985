import { NotificationValueGoalDeleted } from '@lib/types/notification'
import { TFunction } from '@locales/useTranslations'
import { formatDate, TimeStamp } from '@utils/Date'
import {
  formatNotificationSubtitute,
  NOTIFICATION_ACTIONS,
  parseNotificationText,
} from './utils'

export const goalNotifications = (t: TFunction) => ({
  [NOTIFICATION_ACTIONS.NEW_GOAL]: (data: any) => (
    <>
      {t('commentLogs.newGoal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.goal?.name, 70)}"`}{' '}
      </span>
      {t('expressions.hasBeenAssignedToYou')}
    </>
  ),

  [NOTIFICATION_ACTIONS.DELETED_GOAL]: (data: any) => (
    <>
      {t('commentLogs.goal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(
          (data.snapshot as NotificationValueGoalDeleted).value,
          70,
        )}"`}{' '}
      </span>
      {t('commentLogs.hasBeenDeleted')}
    </>
  ),

  [NOTIFICATION_ACTIONS.INPROGRESS_GOAL]: (data: any) => (
    <>
      {t('commentLogs.goal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.goal?.name, 70)}"`}{' '}
      </span>
      {t('commentLogs.hasBeenReturnedToStatusInProgress')}
    </>
  ),

  [NOTIFICATION_ACTIONS.GOAL_DEADLINE_CHANGED]: (data: any) => (
    <>
      {t('commentLogs.deadlineOfGoal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.goal?.name)}"`}{' '}
      </span>
      {t('commentLogs.hasBeenChangedTo')}
      {formatDate(data.snapshot as TimeStamp)}
    </>
  ),

  [NOTIFICATION_ACTIONS.DONE_GOAL]: (data: any) => (
    <>
      {t('commentLogs.goal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.goal?.name, 70)}"`}{' '}
      </span>
      {t('commentLogs.hasBeen')} {t('commentLogs.done')}
    </>
  ),

  [NOTIFICATION_ACTIONS.CANCELED_GOAL]: (data: any) => (
    <>
      {t('commentLogs.goal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.goal?.name, 70)}"`}{' '}
      </span>
      {t('commentLogs.hasBeen')} {t('commentLogs.cancelled')}
    </>
  ),

  [NOTIFICATION_ACTIONS.ASSIGNEE_CHANGED]: (data: any) => (
    <>
      {t('commentLogs.theAssigneeOfGoal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.goal?.name)}"`}{' '}
      </span>
      {formatNotificationSubtitute(data, t)}
    </>
  ),

  [NOTIFICATION_ACTIONS.OWNER_CHANGED]: (data: any) => (
    <>
      {t('commentLogs.theOwnerOfGoal')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(data.goal?.name)}"`}{' '}
      </span>
      {formatNotificationSubtitute(data, t)}
    </>
  ),
})
