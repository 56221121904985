import { ApolloError, FetchPolicy, useQuery } from '@apollo/client'
import { Maybe, Role } from '@lib/graphql/__generated__/graphql'
import { GET_USER_ACCOUNT } from '@lib/graphql/queries/Organization'
import { User } from '@lib/types/user'
import pathOr from 'ramda/src/pathOr'

interface UseUserResponse {
  account: User
  roles: Role[]
  loading: boolean
  error: ApolloError
}

type UseUserParams = {
  fetchPolicy?: FetchPolicy
}

const useUser: (params?: UseUserParams) => UseUserResponse = ({
  fetchPolicy,
} = {}) => {
  const { data, loading, error } = useQuery(GET_USER_ACCOUNT, {
    fetchPolicy,
  })

  const account = data?.getCurrentUserAccount ?? null
  const roles = pathOr([], ['getCurrentUserAccount', 'roles'])(data)

  return {
    account: account as unknown as Maybe<User>,
    roles,
    loading,
    error,
  }
}

export default useUser
