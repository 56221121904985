import { NOTIFICATION_ACTIONS, parseNotificationText } from './utils'

export const exportNotifications = (t: any) => ({
  [NOTIFICATION_ACTIONS.EXPORT_READY]: (data: any) => (
    <>
      {t('commentLogs.export')}{' '}
      <span className="font-semibold">
        {`"${parseNotificationText(
          data.resource?.alias ?? data.resource?.name,
        )}"`}
      </span>{' '}
      {t('commentLogs.isReady')}
    </>
  ),
})
