import UserAccessDropdown from '@components/Shared/Dropdown/UserAccessDropdown'
import UserAvatar from '@components/Shared/UserAvatar/UserAvatar'
import { UUID } from '@lib/types/general'
import { User } from '@lib/types/user'
import ContributorMoreActions from './ContributorMoreActions'
import useTranslations from '@locales/useTranslations'
import { CONTRIBUTOR_RIGHTS } from '@utils/constants'
import OwnerMoreActions from './OwnerMoreActions'
import { useFormikContext } from 'formik'
import { UserAccess } from '@lib/graphql/__generated__/graphql'
import Tooltip from '@components/Shared/Tooltip'

interface IUserContributorProps {
  account: User
  access: string
  teamId?: UUID
  disabled?: boolean
  showMoreActions?: boolean
  showBadge?: boolean
  isProjectResponsible?: boolean
  disableAccessDropdown?: boolean
  onPermissionChange?: Function
  onContributorDelete?: Function
  onOwnerChange?: Function
  showPermissionEdit?: boolean
}

const UserContributor = ({
  account,
  access,
  teamId,
  showMoreActions = true,
  showBadge = true,
  disabled = false,
  onPermissionChange,
  onContributorDelete,
  isProjectResponsible,
  disableAccessDropdown,
  onOwnerChange,
  showPermissionEdit = true,
}: IUserContributorProps) => {
  const { t } = useTranslations()

  const contributorRights = CONTRIBUTOR_RIGHTS(t)

  const { values } = useFormikContext<any>()

  const { id: userId, fullName, jobTitle } = account

  const isOwner = contributorRights.OWNER.value === access

  const actionProps = teamId ? { userId, teamId } : { userId }

  const shouldDisable = disabled || isProjectResponsible

  const actionItems = [
    {
      id: 0,
      label: <span className="text-watermelon-500">{t('buttons.delete')}</span>,
      action: () => onContributorDelete && onContributorDelete(actionProps),
    },
  ]

  const inheritsEditorAccess =
    access === UserAccess.Viewer &&
    values.teamContributors?.find(
      (team) =>
        team.contributors?.find(
          (c) =>
            c?.account?.id === account?.id && c?.access === UserAccess.Editor,
        ),
    )

  return (
    <div className="flex justify-between">
      <div className="flex items-center w-2/3 mr-2">
        <div className="mr-2">
          <UserAvatar name={fullName} accountId={userId} />
        </div>
        <div className="flex flex-col font-sm text-oxford-gray-800">
          <span className="font-semibold text-sm truncate">{fullName}</span>
          <span className="text-xs">{jobTitle?.name}</span>
        </div>

        {showBadge && (
          <div className="ml-2">
            {isProjectResponsible && (
              <span className="text-white bg-[#00b56f] rounded-md p-1 text-xs">
                {t('access.projectOwner')}
              </span>
            )}
            {access === 'OWNER' && (
              <span className="text-white bg-[#9fbbf1] rounded-md p-1 text-xs">
                {t('access.owner')}
              </span>
            )}
          </div>
        )}
      </div>

      <div className="flex">
        {!isOwner && (
          <div className="flex justify-center items-center mr-3">
            {inheritsEditorAccess && (
              <Tooltip content={t('access.userInheritsEditorRightsFromTeam')}>
                <i className="fas fa-exclamation-triangle text-lg leading-none text-candlelight-500 mr-2" />
              </Tooltip>
            )}

            {showPermissionEdit && (
              <div className="min-w-[140px]">
                <UserAccessDropdown
                  allowSearch={false}
                  paddingClass="px-3 py-1"
                  value={
                    isOwner || isProjectResponsible
                      ? contributorRights.EDITOR
                      : contributorRights[access]
                  }
                  onChange={({ value }) =>
                    onPermissionChange({ value, ...actionProps })
                  }
                  dataParser={(data) =>
                    data.filter(
                      ({ value }) =>
                        value !== access &&
                        value !== contributorRights.OWNER.value,
                    )
                  }
                  disabled={disableAccessDropdown || shouldDisable}
                />
              </div>
            )}
          </div>
        )}
        {showMoreActions && !shouldDisable && (
          <div className="flex justify-center items-center h-100">
            {isOwner ? (
              <OwnerMoreActions
                owner={account}
                onOwnerChange={onOwnerChange}
                disabled={disabled}
              />
            ) : (
              <ContributorMoreActions
                actionItems={actionItems}
                disabled={disabled || isProjectResponsible}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default UserContributor
