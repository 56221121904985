import { NotificationValueTaskDeleted } from '@lib/types/notification'
import { TFunction } from '@locales/useTranslations'
import { formatDate, TimeStamp } from '@utils/Date'
import { isToDo, isSubTask } from '@utils/Task'
import {
  NOTIFICATION_ACTIONS,
  parseNotificationText,
  formatNotificationSubtitute,
} from './utils'

export const taskNotifications = (t: TFunction) => ({
  [NOTIFICATION_ACTIONS.NEW_TASK]: (data: any) => (
    <>
      {t(`commentLogs.${isToDo(data.task) ? 'newToDo' : 'newTask'}`)}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.task?.name,
        70,
      )}"`}</span>{' '}
      {t('expressions.hasBeenAssignedToYou')}
    </>
  ),

  [NOTIFICATION_ACTIONS.DELETED_TASK]: (data: any) => (
    <>
      {t(`commentLogs.${isToDo(data.task) ? 'toDo' : 'task'}`)}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        (data.snapshot as NotificationValueTaskDeleted)?.taskName,
        70,
      )}"`}</span>{' '}
      {t('commentLogs.hasBeenDeleted')}
    </>
  ),

  [NOTIFICATION_ACTIONS.OWNER_CHANGED]: (data: any) => (
    <>
      {t(
        `commentLogs.${
          isToDo(data.task) ? 'theOwnerOfToDo' : 'theOwnerOfTask'
        }`,
      )}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.task?.name,
      )}"`}</span>{' '}
      {formatNotificationSubtitute(data, t)}
    </>
  ),

  [NOTIFICATION_ACTIONS.INPROGRESS_TASK]: (data: any) => (
    <>
      {t(`commentLogs.${isToDo(data.task) ? 'toDo' : 'task'}`)}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.task?.name,
        70,
      )}"`}</span>{' '}
      {t('commentLogs.hasBeenReturnedToStatusInProgress')}
    </>
  ),

  [NOTIFICATION_ACTIONS.DEADLINE_CHANGED]: (data: any) => (
    <>
      {t(
        `commentLogs.${
          isToDo(data.task) ? 'deadlineOfToDo' : 'deadlineOfTask'
        }`,
      )}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.task?.name,
      )}"`}</span>{' '}
      {t('commentLogs.hasBeenChangedTo')}{' '}
      {formatDate(data.snapshot as TimeStamp, true)}{' '}
      {data.previousValue !== null ? (
        <>
          ({t('commentLogs.previousDeadline')}:{' '}
          {formatDate(data.previousValue as TimeStamp, true)})
        </>
      ) : (
        ''
      )}
    </>
  ),

  [NOTIFICATION_ACTIONS.DONE_TASK]: (data: any) => (
    <>
      {t(
        `commentLogs.${
          isToDo(data.task) ? 'toDo' : isSubTask(data.task) ? 'subTask' : 'task'
        }`,
      )}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.task?.name,
        70,
      )}"`}</span>{' '}
      {t('commentLogs.hasBeen')} {t('commentLogs.done')}
    </>
  ),

  [NOTIFICATION_ACTIONS.CANCELED_TASK]: (data: any) => (
    <>
      {t(
        `commentLogs.${
          isToDo(data.task) ? 'toDo' : isSubTask(data.task) ? 'subTask' : 'task'
        }`,
      )}{' '}
      <span className="font-semibold">{`"${parseNotificationText(
        data.task?.name,
        70,
      )}"`}</span>{' '}
      {t('commentLogs.hasBeen')} {t('commentLogs.cancelled')}
    </>
  ),

  [NOTIFICATION_ACTIONS.ASSIGNEE_CHANGED]: (data: any) => {
    return (
      <>
        {t(
          `commentLogs.${
            isToDo(data.task) ? 'theAssigneeOfToDo' : 'theAssigneeOfTask'
          }`,
        )}{' '}
        <span className="font-semibold">{`"${parseNotificationText(
          data.task?.name,
        )}"`}</span>{' '}
        {formatNotificationSubtitute(data, t)}
      </>
    )
  },
})
