import { lensPath, view, set } from 'ramda'
import makeEntityDropdown from '../entityControl/makeEntityDropdown'
import UserRenderer from '../Renderer/UserRenderer'
import classNames from 'classnames'
import { WithUserData } from '@components/User/UserProvider/UserProvider'
import { SAVE_ADDRESS } from '@lib/graphql/mutations/Organization'
import { GET_ALL_ADDRESSES } from '@lib/graphql/queries/Organization'

const renderItem = ({ item, inButton }) => {
  return (
    <div
      className={classNames({
        'py-2 pl-2 pr-5': inButton,
        'p-2': !inButton,
      })}
    >
      <UserRenderer value={item} avatarSize={32} withJobTitle={!inButton} />
    </div>
  )
}
const UserDropdown = makeEntityDropdown(WithUserData, {
  allowCreate: false,
  textField: 'fullName',
  renderItem,
  createOptions: {
    operationName: 'SaveUser',
    mutation: SAVE_ADDRESS,
    updateQuery: GET_ALL_ADDRESSES,
    updateResolver: (queryData, result) => {
      const lens = lensPath(['allAccounts'])
      const collection = view(lens, queryData)
      return set(lens, [result.data?.saveAccount, ...collection])(queryData)
    },
  },
  serializeCreate: (data) => ({
    ...data,
    isActive: true,
  }),
})

export default UserDropdown
