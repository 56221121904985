import { FetchPolicy, useQuery } from '@apollo/client'
import { ALL_TEAMS } from '@lib/graphql/queries/Organization'
import { useMemo } from 'react'
import useUser from './useUser'

interface UseTeamsOptions {
  fetchPolicy?: FetchPolicy
  onComplete?: () => void
}

const useTeams = ({
  fetchPolicy = 'cache-first',
  onComplete,
}: UseTeamsOptions = {}) => {
  const { data } = useQuery(ALL_TEAMS, {
    fetchPolicy,
    onCompleted() {
      onComplete?.()
    },
  })

  return data?.allTeams ?? []
}

export const useUserTeams = ({
  fetchPolicy = 'cache-first',
  onComplete,
}: UseTeamsOptions = {}) => {
  const teams = useTeams({ fetchPolicy, onComplete })
  const { account } = useUser()

  const myTeams = useMemo(
    () =>
      teams?.filter(
        ({ members }) =>
          members?.some((member) => member?.account?.id === account?.id),
      ),
    [teams, account.id],
  )

  return myTeams
}

export default useTeams
