import ErrorBoundary from '@components/Error/ErrorBoundary'
import useUsers from '@lib/hooks/useUsers'

export const UserProvider = ({ children, ids = [] }) => {
  const filteredIds = (ids ?? []).filter(Boolean)

  const accounts = useUsers()

  if (filteredIds.length === 0) {
    return children(accounts)
  }

  return children(
    accounts.filter((response) => filteredIds.includes(response?.id)),
  )
}

export const WithUserData = ({ children }) => {
  return (
    <UserProvider>
      {(accounts) => (
        <ErrorBoundary>
          {typeof children === 'function'
            ? children({
                operationName: 'allAccounts',
                data: accounts,
                previousData: null,
                loading: false,
                error: null,
              })
            : children}
        </ErrorBoundary>
      )}
    </UserProvider>
  )
}

export default UserProvider
