import { makeVar, ReactiveVar } from '@apollo/client'
import { UUID } from '@lib/types/general'

export enum TOAST_TYPES {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
interface IToastState {
  id: UUID
  type?: TOAST_TYPES
  message?: string
  show?: boolean
  showBody?: Function
  renderBody?: Function
}

const EXPIRE_TIME_MS = 4000

const MAX_NUMBER_OF_TOASTS = 3

const toastState: ReactiveVar<IToastState[]> = makeVar([])

const showToast = (
  type: TOAST_TYPES,
  message: string,
  renderBody?: Function,
) => {
  let toasts = toastState()
  const id = crypto.randomUUID()

  if (toasts.length > MAX_NUMBER_OF_TOASTS) {
    toasts = toasts.slice(toasts.length + 1 - MAX_NUMBER_OF_TOASTS)
  }

  const newState = [
    ...toasts,
    {
      id,
      type,
      message,
      renderBody,
      show: true,
    },
  ]

  toastState(newState)

  setTimeout(() => {
    removeToast(id)
  }, EXPIRE_TIME_MS)
}

export const showSuccessToast = (message: string) => {
  showToast(TOAST_TYPES.SUCCESS, message)
}

export const showFailureToast = (message: string) => {
  showToast(TOAST_TYPES.FAILURE, message)
}

export const showCustomToast = (
  type: TOAST_TYPES,
  message: string,
  renderBody?: Function,
) => {
  showToast(type, message, renderBody)
}

export const removeToast = (id: UUID) => {
  const updatedState = toastState().map((t) =>
    t.id === id ? { ...t, show: false } : t,
  )

  toastState(updatedState)

  setTimeout(() => {
    const updatedState = toastState().filter((t) => t.id !== id)
    toastState(updatedState)
  }, 1000)
}

export const getToastState = () => toastState
