import translationBG from '@locales/bg/translation.json'
import translationEN from '@locales/en/translation.json'
import translationFR from '@locales/fr/translation.json'

import { Language } from '@lib/graphql/__generated__/graphql'
import gridLocaleBG from '@locales/bg/grid-localeText.json'
import gridLocaleEN from '@locales/en/grid-localeText.json'
import gridLocaleFR from '@locales/fr/grid-localeText.json'

interface Locales {
  [key: string]: {}
}

export const locales: Locales = {
  EN: translationEN,
  FR: translationFR,
  BG: translationBG,
}

export const gridLocales: Locales = {
  EN: gridLocaleEN,
  FR: gridLocaleFR,
  BG: gridLocaleBG,
}

export const EN_LOCALE = Language.En
export const FR_LOCALE = Language.Fr
export const BG_LOCALE = Language.Bg

export const DEFAULT_LOCALE = EN_LOCALE
