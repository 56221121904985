import { Notification } from '@lib/types/notification'
import { TFunction } from '@locales/useTranslations'
import { activityNotifications } from './activityNotifications'
import {
  commentNotifications,
  commentReminderNotifications,
  commentReplyNotifications,
} from './commentNotifications'
import { deadlineRequestNotifications } from './deadlineRequestNotifications'
import { exportNotifications } from './exportNotifications'
import { goalNotifications } from './goalNotifications'
import { projectNotifications } from './projectNotifications'
import { taskNotifications } from './taskNotifications'
import { NOTIFICATION_TYPES } from './utils'

const notificationRegistry = {
  [NOTIFICATION_TYPES.PROJECT]: projectNotifications,
  [NOTIFICATION_TYPES.MILESTONE]: activityNotifications,
  [NOTIFICATION_TYPES.GOAL]: goalNotifications,
  [NOTIFICATION_TYPES.TASK]: taskNotifications,
  [NOTIFICATION_TYPES.COMMENT]: commentNotifications,
  [NOTIFICATION_TYPES.COMMENT_REPLY]: commentReplyNotifications,
  [NOTIFICATION_TYPES.COMMENT_REMINDER]: commentReminderNotifications,
  [NOTIFICATION_TYPES.DEADLINE_REQUEST]: deadlineRequestNotifications,
  [NOTIFICATION_TYPES.EXPORT]: exportNotifications,
}

export const renderNotification = ({
  data,
  t,
}: {
  data: Notification
  t: TFunction
}) => {
  try {
    const typeHandlers = notificationRegistry[data.type](t)
    const renderer = typeHandlers?.[data.action]
    return renderer ? renderer(data) : null
  } catch (error) {
    console.error('Error rendering notification:', error)
    return null
  }
}

export const handleNotificationTransactionEvent = ({
  e,
  gridApi,
  showUnread,
}) => {
  if (!e?.type || !e?.data || !gridApi) return

  const notificationData = e.data?.[0]
  const row = gridApi.getRowNode(notificationData?.id)

  if (showUnread) {
    if (e.type === 'read') {
      if (!row) return

      const displayedRowCount = gridApi.getDisplayedRowCount()
      gridApi.applyServerSideTransaction({
        remove: e.data,
      })

      if (displayedRowCount === 1) gridApi.showNoRowsOverlay()
    }

    if (e.type === 'unread') {
      if (!notificationData || !!row) return

      const notifications = [notificationData]

      gridApi.forEachNode((node) => {
        notifications.push(node.data)
      })

      const rowIndex = notifications
        .sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds)
        .findIndex((v) => v.id === notificationData.id)

      gridApi.applyServerSideTransaction({
        add: e.data,
        addIndex: rowIndex || 0,
      })

      gridApi.hideOverlay()
    }
  } else {
    gridApi.applyServerSideTransaction({
      update: e.data,
    })

    gridApi.refreshCells({
      force: true,
      rowNodes: [row],
    })
  }
}
