import { FetchPolicy, useQuery } from '@apollo/client'
import { ALL_ACCOUNTS } from '@lib/graphql/queries/Organization'

interface UseUsersOptions {
  fetchPolicy?: FetchPolicy
  onComplete?: () => void
}

const useUsers = ({
  fetchPolicy = 'cache-first',
  onComplete,
}: UseUsersOptions = {}) => {
  const { data } = useQuery(ALL_ACCOUNTS, {
    fetchPolicy,
    onCompleted() {
      onComplete?.()
    },
  })

  return data?.allAccounts ?? []
}

export default useUsers
