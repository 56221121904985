import { FilterOperator } from '@lib/graphql/__generated__/graphql'
import { getEndOfDay, getStartOfDay, getTimeStamp } from '@utils/Date'
import { FILTER_OPERATORS, FILTER_TYPES } from './constants'
import {
  mapToNumberValue,
  mapToStringOrBoolOrNumberValue,
  mapToStringValue,
  mapToTimestampValue,
} from './mappers'

const getRangeFilter = (field: string, { from, to }) => {
  return [
    {
      field,
      operator: FILTER_OPERATORS.greaterThanOrEqual,
      values: from,
    },
    {
      field,
      operator: FILTER_OPERATORS.lessThanOrEqual,
      values: to,
    },
  ]
}

export const setFilter = <T = string>(
  field: T,
  filterDef: {
    values: any[]
  },
) => {
  return [
    {
      field,
      operator: FilterOperator.In,
      values: mapToStringOrBoolOrNumberValue(filterDef.values),
    },
  ]
}

export const textFilter = <T = string>(
  field: T,
  filterDef: {
    filter: string
  },
  operator: FilterOperator,
) => {
  return [
    {
      field,
      operator,
      values: mapToStringValue([filterDef.filter]),
    },
  ]
}

export const dateFilter = (
  field: string,
  filterDef: {
    dateFrom: string | Date
    dateTo?: string | Date
  },
  operator: string,
) => {
  if (operator === FILTER_OPERATORS.equals) {
    const dateFromDate = new Date(filterDef.dateFrom)

    return [
      {
        field,
        operator: FilterOperator.Equals,
        values: mapToTimestampValue([
          getTimeStamp(getStartOfDay(dateFromDate), true),
        ]),
      },
    ]
  }

  if (operator === FILTER_OPERATORS.inRange) {
    return calcualateDateRange(field, filterDef.dateFrom, filterDef.dateTo)
  }

  return calculateGreaterOrLessThan(field, operator, filterDef.dateFrom)
}

export const dateTimeFilter = (
  field: string,
  filterDef: {
    dateFrom: string | Date
    dateTo?: string | Date
  },
  operator: string,
) => {
  if (operator === FILTER_OPERATORS.equals) {
    const dateFromDate = new Date(filterDef.dateFrom)

    return getRangeFilter(field, {
      from: mapToTimestampValue([
        getTimeStamp(getStartOfDay(dateFromDate), true),
      ]),
      to: mapToTimestampValue([getTimeStamp(getEndOfDay(dateFromDate), true)]),
    })
  }

  if (operator === FILTER_OPERATORS.inRange) {
    return calcualateDateRange(field, filterDef.dateFrom, filterDef.dateTo)
  }

  return calculateGreaterOrLessThan(field, operator, filterDef.dateFrom)
}

const calcualateDateRange = (
  field: string,
  dateFrom: string | Date,
  dateTo: string | Date,
) => {
  const dateFromDate = new Date(dateFrom)
  const dateToDate = new Date(dateTo)

  return getRangeFilter(field, {
    from: mapToTimestampValue([
      getTimeStamp(getStartOfDay(dateFromDate), true),
    ]),
    to: mapToTimestampValue([getTimeStamp(getEndOfDay(dateToDate), true)]),
  })
}

const calculateGreaterOrLessThan = (
  field: string,
  operator: string,
  dateFrom: string | Date,
) => {
  const dateFn =
    operator === FILTER_OPERATORS.greaterThanOrEqual
      ? getStartOfDay
      : getEndOfDay

  const timeStamp = getTimeStamp(dateFn(new Date(dateFrom)), true)

  return [
    {
      field,
      operator,
      values: mapToTimestampValue([timeStamp]),
    },
  ]
}

export const numberFilter = (
  field: string,
  filterDef: {
    filter: number
    filterTo?: number
  },
  operator: string,
) => {
  if (operator === FILTER_OPERATORS.inRange) {
    return getRangeFilter(field, {
      from: mapToNumberValue([filterDef.filter]),
      to: mapToNumberValue([filterDef.filterTo]),
    })
  }

  return [
    {
      field,
      operator,
      values: mapToNumberValue([filterDef.filter]),
    },
  ]
}

export const filterResolvers = {
  [FILTER_TYPES.DATE]: dateFilter,
  [FILTER_TYPES.DATE_TIME]: dateTimeFilter,
  [FILTER_TYPES.NUMBER]: numberFilter,
  [FILTER_TYPES.TEXT]: textFilter,
  [FILTER_TYPES.SET]: setFilter,
}
