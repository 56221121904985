import UserDropdown from '@components/Shared/Dropdown/UserDropdown'
import UserContributor from '../AccessComponents/UserContributor'
import { useFormikContext } from 'formik'
import { CONTRIBUTOR_RIGHTS, CONTRIBUTOR_TYPE } from '@utils/constants'
import useTranslations from '@locales/useTranslations'
import { User } from '@lib/types/user'
import useContributorActions from '@lib/hooks/useContributorActions'
import ContributorContainer from '../AccessComponents/ContributorContainer'

export interface IPrivateTab {
  resourceOwner: User
  onOwnerChange: Function
}

const PrivateTab = ({ resourceOwner, onOwnerChange }: IPrivateTab) => {
  const { values }: any = useFormikContext()

  const { t } = useTranslations()

  const { OWNER } = CONTRIBUTOR_RIGHTS(t)

  const { onContributorAdd, ...rest } = useContributorActions(
    CONTRIBUTOR_TYPE.USER,
    resourceOwner,
  )

  return (
    <>
      {resourceOwner && (
        <ContributorContainer
          title={t('access.projectOwner')}
          containerClassName="bg-oxford-gray-50"
          contributors={
            <UserContributor
              key={resourceOwner.id}
              account={resourceOwner}
              access={OWNER.value}
              showBadge={false}
              onOwnerChange={onOwnerChange}
            />
          }
        />
      )}

      <div className="py-3">
        <div className="text-oxford-gray-400 uppercase mb-2 text-xs">
          {t('access.users')}
        </div>
        {!!values?.userContributors?.length && (
          <div className="pb-3 flex flex-col gap-3">
            {values.userContributors.map(({ account, access, ...restUser }) => (
              <UserContributor
                key={account.id}
                account={account}
                access={access}
                {...restUser}
                {...rest}
              />
            ))}
          </div>
        )}

        <UserDropdown
          placeholder={t('buttons.addUser')}
          onChange={onContributorAdd}
          dataParser={(data) =>
            data.filter(
              (user) =>
                user.id !== resourceOwner?.id &&
                !values?.userContributors?.find(
                  ({ account }) => user.id === account.id,
                ),
            )
          }
        />
      </div>
    </>
  )
}

export default PrivateTab
