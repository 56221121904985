import { TimeStamp } from '@utils/Date'

const mapTo = (field: string) => (values: string[] | TimeStamp[] | number[]) =>
  values.map((val) => ({
    [field]: val,
  }))

export const mapToStringOrBoolOrNumberValue = (
  values: string[] | boolean[] | number[],
) =>
  values.map((val) => {
    if (typeof val === 'boolean') {
      return { boolValue: val }
    } else if (typeof val === 'number') {
      return { numberValue: val }
    } else {
      return { stringValue: val }
    }
  })

export const mapToStringValue = mapTo('stringValue')
export const mapToNumberValue = mapTo('numberValue')
export const mapToTimestampValue = mapTo('timestampValue')
